.contact-us{
    position: relative;
    height: 90vh;
    background-color: whitesmoke;
}

.contact-us .welcome-banner{
    position: relative;
    height: 20vh;
}

.contact-us .welcome-banner p{
    color: black;
    position: absolute;
    left: 5%;
    font-size: 500%;
    font-family: "Lora", serif;
}

.contact-us .main-contact-us-wrapper{
    position: relative;
    height: 78%;
}

.contact-us .main-contact-us-wrapper .get-in-touch-wrapper{
    position: absolute;
    width: 50%;
    height: 100%;
    border-right: 1px solid rgb(204, 204, 204);
}

.contact-us .main-contact-us-wrapper .get-in-touch-wrapper .get-in-touch-box{
    position: absolute;
    width: 80%;
    height: 80%;
    left: 10%;
    top: 10%;
    border-left: 1px solid rgb(197, 197, 197);
}

.contact-us .main-contact-us-wrapper .get-in-touch-wrapper .get-in-touch-box .get-in-touch-main-box{
    position: absolute;
    width: 80%;
    height: 90%;
    left: 10%;
    top: 5%;
    border-top: 1px solid rgb(197, 197, 197);
}

.contact-us .main-contact-us-wrapper .get-in-touch-wrapper .get-in-touch-box .get-in-touch-main-box .get-in-touch-box-head{
    position: absolute;
    font-size: 350%;
    left: 2%;
    top: 5%;
    font-family: "Lora", serif;
}

.contact-us .main-contact-us-wrapper .get-in-touch-wrapper .get-in-touch-box .get-in-touch-main-box .get-in-touch-desc{
    position: absolute;
    top: 20%;
    left: 4%;
    font-size: 120%;
    font-family: 'Courier New', Courier, monospace;
}

.contact-us .main-contact-us-wrapper .get-in-touch-wrapper .get-in-touch-box .get-in-touch-main-box .contact-info{
    position: absolute;
    top: 35%;
    left: 5%;
    font-size: 120%;
}

.contact-us .main-contact-us-wrapper .contact-form-wrapper{
    position: absolute;
    left: 50%;
    width: 50%;
    height: 100%;
}

.contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box{
    position: absolute;
    width: 80%;
    height: 90%;
    left: 10%;
    top: 5%;
    font-family: "Lora", serif;
}

.contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container{
    position: absolute;
    height: 100%;
    width: 100%;
}

.contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container label{
    position: absolute;
    color: rgb(175, 99, 0);
    top: 9%;
    font-size: 120%;
    font-family: "Lora", serif;
}

.contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container .email-label{
    top: 28%;
}

.contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container .message-label{
    top: 48%;
}

.contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container input{
    position: absolute;
    width: 45%;
    height: 9%;
    outline: none;
    font-size: 110%;
    font-family: 'Courier New', Courier, monospace;

}

.contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container .first-name-input{
    left: 0%;
    top: 13%;
}

.contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container .last-name-input{
    left: 50%;
    top: 13%;
}

.contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container .email-input{
    left: 0%;
    top: 32%;
}

.contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container .message-area{
    position: absolute;
    width: 95%;
    height: 20%;
    top: 53%;
    left: 0%;
    outline: none;
    font-size: 140%;
}

.contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container .send-button{
    position: absolute;
    background-color: whitesmoke;
    width: 18%;
    height: 12%;
    top: 78%;
    left: 0%;
    border: none;
    cursor: pointer;
    font-size: 120%;
    font-family: "Lora", serif;
    border: 1px solid rgb(240, 151, 34);
    transition: 1s;
}

.contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container .send-button:hover{
    background-color: rgb(211, 211, 211);
}

@media screen and (max-width: 600px) {
    .contact-us{
        height: 180vh;
    }
    
    .contact-us .welcome-banner{
        height: 20vh;
    }
    
    .contact-us .welcome-banner p{
        font-size: 350%;
        left: 16%;
    }
    
    .contact-us .main-contact-us-wrapper{
        height: 89%;
    }
    
    .contact-us .main-contact-us-wrapper .get-in-touch-wrapper{
        width: 100%;
        border-bottom: 1px solid rgb(204, 204, 204);
    }



    .contact-us .main-contact-us-wrapper .get-in-touch-wrapper .get-in-touch-box{
        width: 100%;
        height: 100%;
        left: 0%;
        top: 0%;
        border-bottom: 1px solid rgb(197, 197, 197);
    }
    
    .contact-us .main-contact-us-wrapper .get-in-touch-wrapper .get-in-touch-box .get-in-touch-main-box{
        width: 100%;
        height: 100%;
        left: 0%;
        top: 0%;
    }
    
    .contact-us .main-contact-us-wrapper .get-in-touch-wrapper .get-in-touch-box .get-in-touch-main-box .get-in-touch-box-head{
        position: absolute;
        font-size: 250%;
        left: 5%;
        top: 2%;
    }
    
    .contact-us .main-contact-us-wrapper .get-in-touch-wrapper .get-in-touch-box .get-in-touch-main-box .get-in-touch-desc{
        top: 7%;
        left: 5%;
        text-align: center;
        font-size: 106%;
    }
    
    .contact-us .main-contact-us-wrapper .get-in-touch-wrapper .get-in-touch-box .get-in-touch-main-box .contact-info{
        border-top: 1px solid rgb(226, 226, 226);
        height: 30%;
        top: 12%;
        left: 10%;
        font-size: 120%;
        text-align: center;
    }
    
    .contact-us .main-contact-us-wrapper .contact-form-wrapper{
        position: absolute;
        width: 100%;
        height: 50%;
        left: 0%;
        top: 50%;
    }
    
    .contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box{
        width: 100%;
        height: 100%;
        left: 0%;
        top: 0%;
    }
    
    .contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container label{
        color: rgb(175, 99, 0);
        top: 4%;
        left: 40%;
        font-size: 140%;
    }
    
    .contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container .email-label{
        top: 37%;
    }
    
    .contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container .message-label{
        top: 56%;
        left: 35%;
    }
    
    .contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container input{
        width: 80%;
        height: 8%;
    
    }
    
    .contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container .first-name-input{
        left: 8%;
        top: 10%;
    }
    
    .contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container .last-name-input{
        left: 8%;
        top: 24%;
    }
    
    .contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container .email-input{
        left: 8%;
        top: 42%;
    }
    
    .contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container .message-area{
        left: 1%;
        top: 62%;
    }
    
    .contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container .send-button{
        position: absolute;
        background-color: whitesmoke;
        width: 40%;
        height: 8%;
        top: 87%;
        left: 30%;
    }
}

@media only screen and (min-width: 610px) and (max-width: 1200px){
    .contact-us{
        height: 180vh;
    }
    
    .contact-us .welcome-banner{
        height: 20vh;
    }
    
    .contact-us .welcome-banner p{
        font-size: 350%;
        left: 6%;
    }
    
    .contact-us .main-contact-us-wrapper{
        height: 89%;
    }
    
    .contact-us .main-contact-us-wrapper .get-in-touch-wrapper{
        width: 100%;
        border-bottom: 1px solid rgb(204, 204, 204);
    }



    .contact-us .main-contact-us-wrapper .get-in-touch-wrapper .get-in-touch-box{
        width: 100%;
        height: 100%;
        left: 0%;
        top: 0%;
        border-bottom: 1px solid rgb(197, 197, 197);
    }
    
    .contact-us .main-contact-us-wrapper .get-in-touch-wrapper .get-in-touch-box .get-in-touch-main-box{
        position: relative;
        width: 100%;
        height: 50%;
        left: 0%;
        top: 0%;
    }
    
    .contact-us .main-contact-us-wrapper .get-in-touch-wrapper .get-in-touch-box .get-in-touch-main-box .get-in-touch-box-head{
        position: relative;
        font-size: 250%;
        left: 0%;
        text-align: center;
        top: 2%;
    }
    
    .contact-us .main-contact-us-wrapper .get-in-touch-wrapper .get-in-touch-box .get-in-touch-main-box .get-in-touch-desc{
        position: relative;
        top: 2%;
        left: 0%;
        text-align: center;
        font-size: 106%;
    }
    
    .contact-us .main-contact-us-wrapper .get-in-touch-wrapper .get-in-touch-box .get-in-touch-main-box .contact-info{
        position: relative;
        border-top: 1px solid rgb(226, 226, 226);
        height: 60%;
        top: 12%;
        left: 0%;
        font-size: 120%;
        text-align: center;
    }
    
    .contact-us .main-contact-us-wrapper .contact-form-wrapper{
        position: absolute;
        width: 100%;
        height: 50%;
        left: 0%;
        top: 50%;
    }
    
    .contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box{
        width: 100%;
        height: 100%;
        left: 0%;
        top: 0%;
    }
    
    .contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container label{
        color: rgb(175, 99, 0);
        top: 4%;
        left: 40%;
        font-size: 140%;
    }
    
    .contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container .email-label{
        top: 37%;
    }
    
    .contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container .message-label{
        top: 56%;
        left: 35%;
    }
    
    .contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container input{
        width: 80%;
        height: 8%;
    
    }
    
    .contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container .first-name-input{
        left: 8%;
        top: 10%;
    }
    
    .contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container .last-name-input{
        left: 8%;
        top: 24%;
    }
    
    .contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container .email-input{
        left: 8%;
        top: 42%;
    }
    
    .contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container .message-area{
        left: 1%;
        top: 62%;
    }
    
    .contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container .send-button{
        position: absolute;
        background-color: whitesmoke;
        width: 40%;
        height: 8%;
        top: 87%;
        left: 30%;
    }
}

@media only screen and (min-width: 1205px) and (max-width:1600px){
    .contact-us .welcome-banner{
        height: 25vh;
    }
    
    .contact-us .welcome-banner p{
        top: 25%;
    }

    .contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container input{
        width: 50%;
    }

    .contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container .last-name-input{
        left: 55%;
    }

    .contact-us .main-contact-us-wrapper .contact-form-wrapper .contact-form-box .form-container .send-button{
        width: 30%;
        height: 8%;
        left: 35%;
    }
}