.aboutUs{
    position: relative;
    height: 250vh;
}

.aboutUs .about-us-welcome-banner{
    position: absolute;
    width: 90%;
    height: 6%;
    left: 5%;
    top: 6%;
}

.aboutUs .about-us-welcome-banner .about-us-text{
    position: absolute;
    width: 30%;
    left: 2%;
    top: 40%;
    border-bottom: 1px solid rgb(204, 204, 204);
    font-size: 500%;
    font-family: "Lora", serif;
}

.aboutUs .about-algo-trading{
    position: absolute;
    width: 90%;
    height: 5%;
    left: 5%;
    top: 15%;
}

.aboutUs .about-algo-trading .algo-trading-content{
    color: black;
    position: relative;
    top: 10%;
    text-align: center;
    font-size: 140%;
    font-family: "Lora", serif;
}

.aboutUs .pros-wrapper{
    position: relative;
    background-color: whitesmoke;
    height: 45%;
    top: 22%;
}

.aboutUs .pros-wrapper .pros-wrapper-title{
    position: absolute;
    left: 7%;
    top: 6%;
    font-family: "Lora", serif;
    font-size: 500%;
}

.aboutUs .pros-wrapper .pro-list-wrapper{
    position: absolute;
    width: 85%;
    height: 72%;
    left: 7%;
    top: 25%;
}

.aboutUs .pros-wrapper .pro-list-wrapper li{
    list-style-type: none;
}

.aboutUs .pros-wrapper .pro-list-wrapper .pros-point{
    position: absolute;
    width: 30%;
    height: 50%;
    left: 5%;
    border-right: 1px solid rgb(161, 161, 161);
    border-left: 1px solid rgb(161, 161, 161);
    transition: 1s;
}

.aboutUs .pros-wrapper .pro-list-wrapper .pros-point:hover{
    background-color: white;
    box-shadow: 1px 1px 5px;
}

.aboutUs .pros-wrapper .pro-list-wrapper .pros-point-second{
    left: 35%;
    border-left: 0px;
}

.aboutUs .pros-wrapper .pro-list-wrapper .pros-point-third{
    left: 65%;
    border-left: 0px;
}

.aboutUs .pros-wrapper .pro-list-wrapper .pros-point-fourth{
    top: 50%;
    left: 20%;
    border-left: 1px solid rgb(161, 161, 161);
}

.aboutUs .pros-wrapper .pro-list-wrapper .pros-point-fifth{
    top: 50%;
    left: 50.1%;
    border-left: 0px;
}

.aboutUs .pros-wrapper .pro-list-wrapper .pros-point .pros-point-content{
    position: absolute;
    width: 80%;
    height: 80%;
    left: 10%;
    top: 10%;
    border-top: 1px solid rgb(161, 161, 161);
}

.aboutUs .pros-wrapper .pro-list-wrapper .pros-point .pros-point-content .pros-point-no{
    position: absolute;
    top: 4%;
    color: rgb(230, 130, 0);
}

.aboutUs .pros-wrapper .pro-list-wrapper .pros-point .pros-point-content p{
    position: absolute;
    color: black;
    top: 50%;
    font-size: 220%;
    font-family: "Lora", serif;
}

.aboutUs .featured-banner{
    position: relative;
    top: 22%;
    height: 8%;
}

.aboutUs .featured-banner .featured-banner-content{
    position: relative;
    text-align: center;
    top: 30%;
    font-size: 350%;
    font-family: "Lora", serif;
}

@media screen and (max-width: 600px) {
    .aboutUs{
        height: 350vh;
    }
    
    .aboutUs .about-us-welcome-banner{
        height: 5%;
        top: 1%;
    }
    
    .aboutUs .about-us-welcome-banner .about-us-text{
        width: 90%;
        left: 0%;
        font-size: 400%;
    }
    
    .aboutUs .about-algo-trading{
        height: 12%;
        top: 6%;
    }
    
    .aboutUs .about-algo-trading .algo-trading-content{
        top: 0%;
        font-size: 120%;
    }
    
    .aboutUs .pros-wrapper{
        position: relative;
        background-color: whitesmoke;
        height: 70%;
        top: 18%;
    }
    
    .aboutUs .pros-wrapper .pros-wrapper-title{
        width: 95%;
        left: 3%;
        top: 2%;
        font-size: 300%;
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper{
        top: 9%;
        height: 86%;
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper li{
        list-style-type: none;
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper .pros-point{
        width: 100%;
        height: 18%;
        left: 0%;
        top: 1%;
        background-color: white;
        box-shadow: 1px 1px 5px;
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper .pros-point-second{
        top: 21%;
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper .pros-point-third{
        top: 41%;
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper .pros-point-fourth{
        top: 61%;
        border-left: 1px solid rgb(161, 161, 161);
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper .pros-point-fifth{
        top: 82%;
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper .pros-point .pros-point-content p{
        top: 15%;
        font-size: 200%;
    }
    
    .aboutUs .featured-banner{
        height: 12%;
        top: 18%;
    }
    
    .aboutUs .featured-banner .featured-banner-content{
        top: 10%;
        font-size: 250%;
    }
    
}

@media only screen and (min-width: 610px) and (max-width: 1200px){
    .aboutUs{
        height: 350vh;
    }
    
    .aboutUs .about-us-welcome-banner{
        height: 5%;
        top: 1%;
    }
    
    .aboutUs .about-us-welcome-banner .about-us-text{
        width: 90%;
        left: 0%;
        font-size: 400%;
    }
    
    .aboutUs .about-algo-trading{
        position: relative;
        top: 6%;
        height: auto;
    }
    
    .aboutUs .about-algo-trading .algo-trading-content{
        top: 10%;
        font-size: 120%;
    }
    
    .aboutUs .pros-wrapper{
        position: relative;
        background-color: whitesmoke;
        height: 70%;
        top: 9%;
    }
    
    .aboutUs .pros-wrapper .pros-wrapper-title{
        width: 95%;
        left: 3%;
        top: 2%;
        font-size: 300%;
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper{
        top: 9%;
        height: 86%;
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper li{
        list-style-type: none;
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper .pros-point{
        width: 100%;
        height: 18%;
        left: 0%;
        top: 1%;
        background-color: white;
        box-shadow: 1px 1px 5px;
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper .pros-point-second{
        top: 21%;
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper .pros-point-third{
        top: 41%;
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper .pros-point-fourth{
        top: 61%;
        border-left: 1px solid rgb(161, 161, 161);
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper .pros-point-fifth{
        top: 82%;
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper .pros-point .pros-point-content p{
        top: 15%;
        font-size: 200%;
    }
    
    .aboutUs .featured-banner{
        height: 12%;
        top: 10%;
    }
    
    .aboutUs .featured-banner .featured-banner-content{
        top: 10%;
        font-size: 250%;
    }
}



@media only screen and (min-width: 1205px) and (max-width:1600px){
    .aboutUs{
        position: relative;
        height: 250vh;
    }
    
    .aboutUs .about-us-welcome-banner{
        position: relative;
        left: 0%;
        width: 100%;
        top: 8%;
    }
    
    .aboutUs .about-us-welcome-banner .about-us-text{
        position: absolute;
        width: 40%;
        left: 4%;
        top: 20%;
        border-bottom: 1px solid rgb(204, 204, 204);
        font-size: 500%;
        font-family: "Lora", serif;
    }
    
    .aboutUs .about-algo-trading{
        position: relative;
        width: 100%;
        height: 6%;
        left: 0%;
        top: 8%;
    }
    
    .aboutUs .about-algo-trading .algo-trading-content{
        color: black;
        position: relative;
        top: 10%;
        text-align: center;
        font-size: 140%;
        font-family: "Lora", serif;
    }
    
    .aboutUs .pros-wrapper{
        position: relative;
        background-color: whitesmoke;
        height: 45%;
        top: 14%;
    }
    
    .aboutUs .pros-wrapper .pros-wrapper-title{
        position: absolute;
        left: 7%;
        top: 6%;
        font-family: "Lora", serif;
        font-size: 500%;
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper{
        position: relative;
        left: 0%;
        width: 100%;
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper li{
        list-style-type: none;
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper .pros-point{
        position: absolute;
        width: 30%;
        height: 50%;
        left: 5%;
        border-right: 1px solid rgb(161, 161, 161);
        border-left: 1px solid rgb(161, 161, 161);
        transition: 1s;
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper .pros-point:hover{
        background-color: white;
        box-shadow: 1px 1px 5px;
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper .pros-point-second{
        left: 35%;
        border-left: 0px;
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper .pros-point-third{
        left: 65%;
        border-left: 0px;
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper .pros-point-fourth{
        top: 50%;
        left: 20%;
        border-left: 1px solid rgb(161, 161, 161);
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper .pros-point-fifth{
        top: 50%;
        left: 50.1%;
        border-left: 0px;
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper .pros-point .pros-point-content{
        position: absolute;
        width: 80%;
        height: 80%;
        left: 10%;
        top: 10%;
        border-top: 1px solid rgb(161, 161, 161);
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper .pros-point .pros-point-content .pros-point-no{
        position: absolute;
        top: 4%;
        color: rgb(230, 130, 0);
    }
    
    .aboutUs .pros-wrapper .pro-list-wrapper .pros-point .pros-point-content p{
        position: absolute;
        color: black;
        top: 13%;
        font-size: 220%;
        font-family: "Lora", serif;
    }
    
    .aboutUs .featured-banner{
        position: relative;
        top: 22%;
        height: 8%;
    }
    
    .aboutUs .featured-banner .featured-banner-content{
        position: relative;
        text-align: center;
        top: 30%;
        font-size: 350%;
        font-family: "Lora", serif;
    }
    
}