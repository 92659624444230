.no-scroll {
    overflow: hidden;
}

body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

.menu-bar {
    background-color: rgb(245, 245, 245,0.9);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 10%;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    box-shadow: 1px 1px 8px;
}

.menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.logo {
    font-size: 24px;
}

.logo .logo-main{
    position: absolute;
    top: 10%;
    left: 5%;
    font-family: "Lora", serif;
    font-size: 200%;
}

.logo .logo-sub{
    position: absolute;
    left: 11%;
    top: 70%;
    font-family: "Courier Prime", monospace;
    color: rgb(247, 150, 23);
    font-size: 80%;
    font-weight: bold;
}

.menu-fullscreen {
    position: absolute;
    width: 48%;
    height: 80%;
    left: 50%;
    top: 8%;
}

.menu-options-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: center;
}

.menu-options-list li {
    display: flex;
    float: left;
    margin-left: 3%;
    padding: 2.5%;
    color: black;
    font-size: 20px;
    font-family: 'Courier New', Courier, monospace;
    border-radius: 5px;
    transition: 2s;
}

.close-button{
    display: none;
}

.menu-button{
    display: none;
}

/* Mobile-specific styles */
@media screen and (max-width: 600px) {
    .menu-bar{
        position: relative;
        height: 10vh;
    }

    .menu-header {
        position: relative;
        height: 100%;
    }

    .logo{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0%;
        left: 0%;
        font-size: 120%;
    }

    .logo .logo-main{
        left: 18%;
    }

    .logo .logo-sub{
        top: 58%;
        left: 30%;
    }

    .menu-button{
        position: absolute;
        display: flex;
        width: 35px;
        height: 50px;
        top: 25%;
        left: 85%;
        font-size: 250%;
        font-weight: bold;
        border: none;
        background-color: rgb(255, 255, 255, 0);
    }

    .menu-fullscreen{
        display: none;
    }

    .menu-fullscreen.show{
        position: absolute;
        background-color: whitesmoke;
        left: 0%;
        top: 0%;
        width: 100%;
        height: 939px;
        display: block;
    }

    .close-button{
        display: block;
        position: absolute;
        color: black;
        width: 80px;
        height: 50px;
        left: 75%;
        top: 4%;
        font-size: 300%;
        font-weight: bolder;
        border: none;
        background-color: rgb(255, 255, 255, 0);
    }

    .menu-options-list{
        position: absolute;
        width: 60%;
        height: 60%;
        left: 20%;
        top: 15%;
    }

    .menu-options-list li{
        display: flex;
        flex-direction: row;
        width: 65%;
        padding: 8%;
        margin-left: 10%;
        margin-top: 5%;
        font-size: 25px;
        background-color: rgb(196, 196, 196);
    }
}

/* tablet-specific styles */
@media only screen and (min-width: 610px) and (max-width: 1200px){
    .menu-bar{
        position: relative;
        height: 10vh;
    }

    .menu-header {
        position: relative;
        height: 100%;
    }

    .logo{
        position: relative;
        width: 100%;
        height: 100%;
        top: 0%;
        left: 0%;
        font-size: 120%;
    }

    .logo .logo-main{
        position: relative;
        left: 0%;
        text-align: center;
    }

    .logo .logo-sub{
        position: relative;
        top: 10%;
        left: 0%;
        text-align: center;
    }

    .menu-button{
        position: absolute;
        display: flex;
        width: 35px;
        height: 50px;
        top: 25%;
        left: 85%;
        font-size: 250%;
        font-weight: bold;
        border: none;
        background-color: rgb(255, 255, 255, 0);
    }

    .menu-fullscreen{
        display: none;
    }

    .menu-fullscreen.show{
        position: absolute;
        background-color: whitesmoke;
        left: 0%;
        top: 0%;
        width: 100%;
        height: 939px;
        display: block;
    }

    .close-button{
        display: block;
        position: absolute;
        color: black;
        width: 80px;
        height: 50px;
        left: 75%;
        top: 4%;
        font-size: 300%;
        font-weight: bolder;
        border: none;
        background-color: rgb(255, 255, 255, 0);
    }

    .menu-options-list{
        position: relative;
        height: 80%;
        left: 0%;
        top: 8%;
    }

    .menu-options-list li{
        display: flex;
        flex-direction: row;
        width: 45%;
        padding: 2%;
        margin-left: 25%;
        margin-top: 5%;
        font-size: 25px;
        background-color: rgb(196, 196, 196);
    }
}


@media only screen and (min-width: 1205px) and (max-width:1600px){
    .menu-bar {
        background-color: rgb(245, 245, 245,0.9);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 10%;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        box-shadow: 1px 1px 8px;
    }
    
    .menu-header {
        position: relative;
        height: 100%;
    }
    
    .logo {
        font-size: 24px;
    }
    
    .logo .logo-main{
        position: absolute;
        top: 10%;
        left: 5%;
        font-family: "Lora", serif;
        font-size: 200%;
    }
    
    .logo .logo-sub{
        position: absolute;
        left: 11%;
        top: 70%;
        font-family: "Courier Prime", monospace;
        color: rgb(247, 150, 23);
        font-size: 80%;
        font-weight: bold;
    }
    
    .menu-fullscreen {
        position: absolute;
        width: 48%;
        height: 45%;
        left: 51%;
        top: 32%;
    }
    
    .menu-options-list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        text-align: center;
    }
    
    .menu-options-list li {
        display: flex;
        float: left;
        margin-left: 3%;
        padding: 1%;
        color: black;
        font-size: 18px;
        font-family: 'Courier New', Courier, monospace;
        border-radius: 5px;
        transition: 2s;
    }
    
    .close-button{
        display: none;
    }
    
    .menu-button{
        display: none;
    }
    
}

@media only screen and (min-width: 1602px) and (max-width:1775px){

    .menu-bar {
        background-color: rgb(245, 245, 245,0.9);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 10%;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        box-shadow: 1px 1px 8px;
    }

    .menu-header {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
    }

    .logo {
        font-size: 24px;
    }

    .logo .logo-main{
        position: absolute;
        top: 10%;
        left: 5%;
        font-family: "Lora", serif;
        font-size: 200%;
    }

    .logo .logo-sub{
        position: absolute;
        left: 11%;
        top: 70%;
        font-family: "Courier Prime", monospace;
        color: rgb(247, 150, 23);
        font-size: 80%;
        font-weight: bold;
    }

    .menu-fullscreen {
        position: absolute;
        width: 48%;
        height: 60%;
        left: 50%;
        top: 20%;
    }

    .menu-options-list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        text-align: center;
    }

    .menu-options-list li {
        display: flex;
        float: left;
        margin-left: 3%;
        padding: 2.5%;
        color: black;
        font-size: 17px;
        font-family: 'Courier New', Courier, monospace;
        border-radius: 5px;
        transition: 2s;
    }

    .close-button{
        display: none;
    }

    .menu-button{
        display: none;
    }

}