.footer{
    position: relative;
    height: 40vh;
    background-color: wheat;
}

.footer .upper-footer{
    position: absolute;
    width: 100%;
    height: 80%;
}

.footer .lower-footer{
    position: absolute;
    width: 100%;
    height: 20%;
    top: 80%;
    border-top: 1px solid rgb(199, 199, 199);
}

.footer .upper-footer .usefull-links, .additional-links{
    position: absolute;
    width: 15%;
    height: auto;
    left: 5%;
    top: 15%;
}

.footer .upper-footer .usefull-links .usefull-link-items, .additional-links-items{
    display: flex;
    padding: 0.5%;
    color: black;
    font-size: 100%;
    font-family: 'Courier New', Courier, monospace;
    text-decoration: none;
    transition: 0.2s;
}

.footer .upper-footer .usefull-links .usefull-link-items:hover, .additional-links-items:hover{
    color: rgb(240, 144, 18);
}

.footer .upper-footer .additional-links{
    position: absolute;
    height: 80%;
    left: 85%;
    top: 15%;
}

.footer .upper-footer .additional-links-items{
    padding: 2%;
}

.info-detail{
    position:absolute;
    width: 40%;
    height: 90%;
    left: 30%;
    top : 5%;
}

.info-detail .logo{
    position: absolute;
    width: 50%;
    height: 35%;
    left: 23%;
}

.info-detail .logo .logo-main{
    position: absolute;
    top: 10%;
    font-family: "Lora", serif;
    font-size: 180%;
}

.info-detail .logo .logo-sub{
    position: absolute;
    left: 22%;
    top: 72%;
    font-family: "Courier Prime", monospace;
    color: rgb(240, 151, 34);
    font-size: 90%;
    font-weight: bolder;
}

.info-detail .info-detail-desc{
    position: absolute;
    text-align: center;
    top: 34%;
    font-size: 105%;
    font-family: 'Courier New', Courier, monospace;
}

.lower-footer .social-media{
    position: absolute;
    width: 20%;
    height: 80%;
    left: 40%;
}

.footer .social-media .footer-icon{
    display: inline-block;
    padding: 3%;
    margin-left: 11%;
    margin-top: 2%;
}

@media screen and (max-width: 600px) {
    .footer{
        height: 100vh;
    }
    
    .footer .upper-footer{
        width: 100%;
        height: 90%;
    }
    
    .footer .lower-footer{
        width: 100%;
        height: 20%;
        top: 80%;
        border-top: 1px solid rgb(199, 199, 199);
    }
    
    .footer .upper-footer .usefull-links{
        width: 100%;
        top: 30%;
        left: 0%;
    }

    .footer .upper-footer .usefull-links .usefull-link-items{
        padding: 2%;
        margin-left: 25%;
        color: rgb(206, 117, 0);
    }
    
    
    .footer .upper-footer .additional-links{
        display: none;
    }
    
    .info-detail{
        position: relative;
        width: 100%;
        height: 30%;
        left: 0%;
        top: 0%;
    }
    
    .info-detail .logo{
        width: 100%;
        height: 25%;
        top: 15%;
        left: 0%;
    }

    .info-detail .logo .logo-main{
        left: 23%;
    }

    .info-detail .logo .logo-sub{
        top: 75%;
        left: 30%;
    }

    .info-detail .info-detail-desc{
        position: absolute;
        left: 0%;
        top: 45%;
        width: 100%;
        font-size: 100%;
        color: black;
    }
    
    .lower-footer .social-media{
        position: absolute;
        width: 20%;
        height: 80%;
        left: 40%;
    }
    
    .footer .social-media .footer-icon{
        display: inline-block;
        padding: 3%;
        margin-left: 11%;
        margin-top: 2%;
    }
}

@media only screen and (min-width: 610px) and (max-width: 1200px){
    .footer{
        height: 70vh;
    }
    
    .footer .upper-footer{
        width: 100%;
        height: 90%;
    }
    
    .footer .lower-footer{
        width: 100%;
        height: 20%;
        top: 80%;
        border-top: 1px solid rgb(199, 199, 199);
    }
    
    .footer .upper-footer .usefull-links{
        width: 100%;
        height: 52%;
        top: 36%;
        left: 0%;
    }

    .footer .upper-footer .usefull-links .usefull-link-items{
        position: relative;
        text-align: center;
        padding: 0.5%;
        margin-left: 40%;
        color: rgb(206, 117, 0);
    }
    
    
    .footer .upper-footer .additional-links{
        display: none;
    }
    
    .info-detail{
        position: relative;
        width: 100%;
        height: 30%;
        left: 0%;
        top: 0%;
    }
    
    .info-detail .logo{
        width: 100%;
        height: 50%;
        top: 8%;
        left: 0%;
    }

    .info-detail .logo .logo-main{
        position: relative;
        text-align: center;
    }

    .info-detail .logo .logo-sub{
        position: relative;
        text-align: center;
        left: 0%;
        top: 12%;
    }

    .info-detail .info-detail-desc{
        position: absolute;
        left: 0%;
        top: 58%;
        width: 100%;
        font-size: 100%;
        color: black;
    }
    
    .lower-footer .social-media{
        position: absolute;
        width: 20%;
        height: 80%;
        left: 40%;
    }
    
    .footer .social-media .footer-icon{
        display: inline-block;
        padding: 3%;
        margin-left: 11%;
        margin-top: 2%;
    }
}


@media only screen and (min-width: 1205px) and (max-width:1600px){
    .footer{
        position: relative;
        height: 40vh;
        background-color: wheat;
    }
    
    .footer .upper-footer{
        position: absolute;
        width: 100%;
        height: 80%;
    }
    
    .footer .lower-footer{
        position: absolute;
        width: 100%;
        height: 20%;
        top: 80%;
        border-top: 1px solid rgb(199, 199, 199);
    }
    
    .footer .upper-footer .usefull-links, .additional-links{
        position: absolute;
        width: 15%;
        height: auto;
        left: 5%;
        top: 15%;
    }
    
    .footer .upper-footer .usefull-links .usefull-link-items, .additional-links-items{
        display: flex;
        padding: 0.5%;
        color: black;
        font-size: 100%;
        font-family: 'Courier New', Courier, monospace;
        text-decoration: none;
        transition: 0.2s;
    }
    
    .footer .upper-footer .usefull-links .usefull-link-items:hover, .additional-links-items:hover{
        color: rgb(240, 144, 18);
    }
    
    .footer .upper-footer .additional-links{
        position: absolute;
        height: 80%;
        left: 85%;
        top: 15%;
    }
    
    .footer .upper-footer .additional-links-items{
        padding: 2%;
    }
    
    .info-detail{
        position: relative;
        width: 100%;
        left: 0%;
    }
    
    .info-detail .logo{
        position: relative;
        text-align: center;
        left: 0%;
        width: 100%;
        height: 28%;
    }
    
    .info-detail .logo .logo-main{
        position: relative;
        text-align: center;
        left: 0%;
        top: 0%;
        font-size: 150%;
    }
    
    .info-detail .logo .logo-sub{
        position: relative;
        text-align: center;
        top: 1%;
        left: 0%;
        color: rgb(240, 151, 34);
        font-size: 60%;
    }
    
    .info-detail .info-detail-desc{
        position: relative;
        text-align: center;
        top: 0%;
    }
    
    .lower-footer .social-media{
        position: absolute;
        width: 20%;
        height: 80%;
        left: 40%;
    }
    
    .footer .social-media .footer-icon{
        display: inline-block;
        padding: 3%;
        margin-left: 11%;
        margin-top: 2%;
    }    
}