.login{
    position: relative;
    height: 100vh;
}

.login .login-head{
    position: relative;
    text-align: center;
    font-family: "Lora", serif;
    font-size: 320%;
    top: 2%;
}

.login .login-box-wrapper{
    position: absolute;
    width: 40%;
    height: 75%;
    background-color: whitesmoke;
    left: 30%;
    top: 18%;
    border-radius: 10px;
    box-shadow: 2px 2px 5px;
}

.login .login-box-wrapper .login-form{
    position: relative;
    height: 40%;
    top: 5%;
}

.login .login-box-wrapper .login-form input{
    position: absolute;
    width: 50%;
    height: 16%;
    outline: none;
    border: none;
    font-size: 120%;
    background-color: rgb(255, 255, 255,0);
    font-family: 'Courier New', Courier, monospace;
    border-bottom: 2px solid black;
}

.login .login-box-wrapper .login-form .email-input{
    left: 25%;
}

.login .login-box-wrapper .login-form .password-input{
    left: 25%;
    top: 28%;
}

.login .login-box-wrapper .login-form .term-condition{
    position: absolute;
    width: 51%;
    height: 8%;
    top: 50%;
    left: 25%;
    font-size: 85%;
    font-family: 'Courier New', Courier, monospace;
}

.login .login-box-wrapper .login-form .term-condition label{
    position: absolute;
    left: 12%;
    top: 20%;
}

.login .login-box-wrapper .login-form .term-condition input{
    position: absolute;
    width: 20%;
    height: 80%;
    left: -4%;
    border: none;
    outline: none;
}

.login .login-box-wrapper .login-form .login-input{
    position: absolute;
    width: 20%;
    height: 20%;
    top: 70%;
    left: 38%;
    border: 1px solid;
    outline: none;
    font-family: "Lora", serif;
    font-size: 180%;
    background-color: whitesmoke;
    transition: 0.5s;
}

.login .login-box-wrapper .login-form .login-input:hover{
    box-shadow: 1px 1px 5px black;
    border: 1px solid white;
}

.login .login-box-wrapper .login-other-option-wrapper{
    position: absolute;
    width: 80%;
    height: 45%;
    left: 10%;
    margin-top: 4%;
}


.login .login-box-wrapper .login-other-option-wrapper .login-option{
    background-color: darkcyan;
    position: absolute;
    width: 50%;
    height: 15%;
    left: 25%;
    top: 12%;
    color: black;
    border-radius: 200px;
}

.login .login-box-wrapper .login-other-option-wrapper .login-option p{
    position: absolute;
    font-family: 'Courier New', Courier, monospace;
    font-size: 120%;
    top: -10%;
    left: 20%;
    color: black;
}

.login .login-box-wrapper .login-other-option-wrapper .login-with-phone-number{
    top: 34%;
}

.login .login-box-wrapper .login-other-option-wrapper .sign-up{
    position: relative;
    height: 20%;
    top: 55%;
    text-align: center;
    font-size: 100%;
    font-family: 'Courier New', Courier, monospace;
}

.signup-link{
    color: rgb(247, 150, 23);
    font-size: 120%;
    font-family: "Lora", serif;
}

@media screen and (max-width: 600px) {
    .login{
        position: relative;
        height: 100vh;
    }
    .login .login-head{
        font-size: 220%;
        top: 3%;
    }
        
    .login .login-box-wrapper{
        width: 90%;
        height: 96%;
        left: 5%;
        top: 3%;
    }

    .login .login-box-wrapper .login-form{
        height: 40%;
        top: 5%;
    }
    
    .login .login-box-wrapper .login-form input{
        width: 70%;
        height: 15%;
    }
    
    .login .login-box-wrapper .login-form .email-input{
        left: 15%;
    }
    
    .login .login-box-wrapper .login-form .password-input{
        left: 15%;
    }
    
    .login .login-box-wrapper .login-form .term-condition input{
        left: -10%;
        top: 20%;
    }

    .login .login-box-wrapper .login-form .login-input{
        width: 40%;
        height: 15%;
        left: 30%;
    }

    .login .login-box-wrapper .login-other-option-wrapper .login-option{
        width: 90%;
        left: 6%;
    }

    .login .login-box-wrapper .login-other-option-wrapper .login-option p{
        left: 25px;
        top: -1px;
    }

}


@media only screen and (min-width: 610px) and (max-width: 1200px){
    .login{
        position: relative;
        height: 100vh;
    }
    .login .login-head{
        font-size: 220%;
        top: 3%;
    }
        
    .login .login-box-wrapper{
        width: 90%;
        height: 96%;
        left: 5%;
        top: 3%;
    }

    .login .login-box-wrapper .login-form{
        height: 40%;
        top: 5%;
    }
    
    .login .login-box-wrapper .login-form input{
        width: 70%;
        height: 15%;
    }
    
    .login .login-box-wrapper .login-form .email-input{
        left: 15%;
    }
    
    .login .login-box-wrapper .login-form .password-input{
        left: 15%;
    }
    
    .login .login-box-wrapper .login-form .term-condition input{
        left: -10%;
        top: 20%;
    }

    .login .login-box-wrapper .login-form .login-input{
        width: 40%;
        height: 15%;
        left: 30%;
    }

    .login .login-box-wrapper .login-other-option-wrapper .login-option{
        width: 90%;
        left: 6%;
    }

    .login .login-box-wrapper .login-other-option-wrapper .login-option p{
        position: relative;
        left: 0%;
        text-align: center;
        top: 10%;
    }

}


@media only screen and (min-width: 1200px){
    .login{
        position: relative;
        height: 100vh;
    }
    
    .login .login-head{
        position: relative;
        text-align: center;
        font-family: "Lora", serif;
        font-size: 320%;
        top: 2%;
    }
    
    .login .login-box-wrapper{
        position: absolute;
        width: 40%;
        height: 75%;
        background-color: whitesmoke;
        left: 30%;
        top: 18%;
        border-radius: 10px;
        box-shadow: 2px 2px 5px;
    }
    
    .login .login-box-wrapper .login-form{
        position: relative;
        height: 40%;
        top: 5%;
    }
    
    .login .login-box-wrapper .login-form input{
        position: absolute;
        width: 50%;
        height: 16%;
        outline: none;
        border: none;
        font-size: 120%;
        background-color: rgb(255, 255, 255,0);
        font-family: 'Courier New', Courier, monospace;
        border-bottom: 2px solid black;
    }
    
    .login .login-box-wrapper .login-form .email-input{
        left: 25%;
    }
    
    .login .login-box-wrapper .login-form .password-input{
        left: 25%;
        top: 28%;
    }
    
    .login .login-box-wrapper .login-form .term-condition{
        position: absolute;
        width: 51%;
        height: 8%;
        top: 50%;
        left: 25%;
        font-size: 85%;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .login .login-box-wrapper .login-form .term-condition label{
        position: absolute;
        left: 12%;
        top: 20%;
    }
    
    .login .login-box-wrapper .login-form .term-condition input{
        position: absolute;
        width: 20%;
        height: 80%;
        left: -4%;
        border: none;
        outline: none;
    }
    
    .login .login-box-wrapper .login-form .login-input{
        left: 40%;
    }

    .login .login-box-wrapper .login-other-option-wrapper{
        position: relative;
        width: 100%;
        height: 45%;
        left: 0%;
        margin-top: 4%;
    }
    
    .login .login-box-wrapper .login-other-option-wrapper .login-option p{
        position: relative;
        text-align: center;
        left: 0%;
        top: 1%;
    }   
}


@media only screen and (min-width: 1205px) and (max-width:1600px){
    .login{
        position: relative;
        height: 100vh;
    }
    
    .login .login-head{
        position: relative;
        text-align: center;
        font-family: "Lora", serif;
        font-size: 320%;
        top: 2%;
    }
    
    .login .login-box-wrapper{
        position: absolute;
        width: 40%;
        height: 75%;
        background-color: whitesmoke;
        left: 30%;
        top: 18%;
        border-radius: 10px;
        box-shadow: 2px 2px 5px;
    }
    
    .login .login-box-wrapper .login-form{
        position: relative;
        height: 40%;
        top: 5%;
    }
    
    .login .login-box-wrapper .login-form input{
        position: absolute;
        width: 50%;
        height: 16%;
        outline: none;
        border: none;
        font-size: 120%;
        background-color: rgb(255, 255, 255,0);
        font-family: 'Courier New', Courier, monospace;
        border-bottom: 2px solid black;
    }
    
    .login .login-box-wrapper .login-form .email-input{
        left: 25%;
    }
    
    .login .login-box-wrapper .login-form .password-input{
        left: 25%;
        top: 28%;
    }
    
    .login .login-box-wrapper .login-form .term-condition{
        position: absolute;
        width: 51%;
        height: 8%;
        top: 50%;
        left: 25%;
        font-size: 85%;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .login .login-box-wrapper .login-form .term-condition label{
        position: absolute;
        left: 12%;
        top: 20%;
    }
    
    .login .login-box-wrapper .login-form .term-condition input{
        position: absolute;
        width: 20%;
        height: 80%;
        left: -4%;
        border: none;
        outline: none;
    }
    
    .login .login-box-wrapper .login-form .login-input{
        position: absolute;
        width: 22%;
        height: 18%;
        top: 74%;
        left: 38%;
        border: 1px solid;
        outline: none;
        font-family: "Lora", serif;
        font-size: 180%;
        background-color: whitesmoke;
        transition: 0.5s;
    }
    
    .login .login-box-wrapper .login-form .login-input:hover{
        box-shadow: 1px 1px 5px black;
        border: 1px solid white;
    }
    
    .login .login-box-wrapper .login-other-option-wrapper{
        position: relative;
    }
    
    
    .login .login-box-wrapper .login-other-option-wrapper .login-option{
        background-color: darkcyan;
        position: absolute;
        width: 58%;
        height: 15%;
        left: 22%;
        top: 12%;
        color: black;
        border-radius: 200px;
    }
    
    .login .login-box-wrapper .login-other-option-wrapper .login-option p{
        position: relative;
        text-align: center;
        top: -15%;
    }
    
    .login .login-box-wrapper .login-other-option-wrapper .login-with-phone-number{
        top: 34%;
    }
    
    .login .login-box-wrapper .login-other-option-wrapper .sign-up{
        position: relative;
        height: 20%;
        top: 65%;
        text-align: center;
        font-size: 100%;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .signup-link{
        color: rgb(247, 150, 23);
        font-size: 120%;
        font-family: "Lora", serif;
    }    
}


@media only screen and (min-width: 1602px) and (max-width:1775px){
    .login{
        position: relative;
        height: 100vh;
    }
    
    .login .login-head{
        position: relative;
        text-align: center;
        font-family: "Lora", serif;
        font-size: 320%;
        top: 2%;
    }
    
    .login .login-box-wrapper{
        position: absolute;
        width: 40%;
        height: 75%;
        background-color: whitesmoke;
        left: 30%;
        top: 18%;
        border-radius: 10px;
        box-shadow: 2px 2px 5px;
    }
    
    .login .login-box-wrapper .login-form{
        position: relative;
        height: 40%;
        top: 5%;
    }
    
    .login .login-box-wrapper .login-form input{
        position: absolute;
        width: 50%;
        height: 16%;
        outline: none;
        border: none;
        font-size: 120%;
        background-color: rgb(255, 255, 255,0);
        font-family: 'Courier New', Courier, monospace;
        border-bottom: 2px solid black;
    }
    
    .login .login-box-wrapper .login-form .email-input{
        left: 25%;
    }
    
    .login .login-box-wrapper .login-form .password-input{
        left: 25%;
        top: 28%;
    }
    
    .login .login-box-wrapper .login-form .term-condition{
        position: absolute;
        width: 51%;
        height: 8%;
        top: 50%;
        left: 25%;
        font-size: 85%;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .login .login-box-wrapper .login-form .term-condition label{
        position: absolute;
        left: 12%;
        top: 20%;
    }
    
    .login .login-box-wrapper .login-form .term-condition input{
        position: absolute;
        width: 20%;
        height: 80%;
        left: -4%;
        border: none;
        outline: none;
    }
    
    .login .login-box-wrapper .login-form .login-input{
        position: absolute;
        width: 20%;
        height: 20%;
        top: 70%;
        left: 38%;
        border: 1px solid;
        outline: none;
        font-family: "Lora", serif;
        font-size: 180%;
        background-color: whitesmoke;
        transition: 0.5s;
    }
    
    .login .login-box-wrapper .login-form .login-input:hover{
        box-shadow: 1px 1px 5px black;
        border: 1px solid white;
    }
    
    .login .login-box-wrapper .login-other-option-wrapper{
        position: relative;
    }
    
    
    .login .login-box-wrapper .login-other-option-wrapper .login-option{
        background-color: darkcyan;
        position: absolute;
        width: 50%;
        height: 15%;
        left: 25%;
        top: 12%;
        color: black;
        border-radius: 200px;
    }
    
    .login .login-box-wrapper .login-other-option-wrapper .login-option p{
        position: relative;
        top: -10%;
    }
    
    .login .login-box-wrapper .login-other-option-wrapper .login-with-phone-number{
        top: 34%;
    }
    
    .login .login-box-wrapper .login-other-option-wrapper .sign-up{
        position: relative;
        height: 20%;
        top: 55%;
        text-align: center;
        font-size: 100%;
        font-family: 'Courier New', Courier, monospace;
    }
    
    .signup-link{
        color: rgb(247, 150, 23);
        font-size: 120%;
        font-family: "Lora", serif;
    }    
}