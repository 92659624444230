.services-container {
    position: relative;
    height: 150vh;
    padding: 50px;
    text-align: center;
  }
  
  h1 {
    top: 5%;
    font-size: 3em;
    margin-bottom: 30px;
    color: #724444;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
    position: relative;
  }
  h1::after {
    content: '';
    display: block;
    width: 100px;
    height: 5px;
    background-color: #31455a; /* Underline color */
    margin: 10px auto 0;
    border-radius: 5px;
  }
  
  .services-grid {
    margin-top: 6%;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns */
    gap: 20px; /* Space between the cards */
  }
    
  .service-card {
    margin: 20px auto;
    padding: 20px;
    max-width: 600px;
    border-radius: 8px;
    border: 2px solid #dfe0e2; /* Rectangular border */
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    overflow: hidden;
  }
  .service-card img {
    width: 100%;
    height: auto;
    max-height: 200px;
    border-bottom: 2px solid #f8f7ef;
    margin-bottom: 15px;
  }
  .service-card h2 {
    font-size: 1.75em;
    margin-bottom: 10px;
    color: #1f354d;
  }
  
  .service-card p {
    font-size: 1em;
    color: #555;
    line-height: 1.6;
  }
  .service-card:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 123, 255, 0.3);
    border-color: #eebdeb; /* Glow effect on hover */
  }
  
  @media (max-width: 768px) {
    .services-container {
        height: 380vh;
    }

    h1{
        top: -1%;
    }

    .services-grid {
      margin-top: -15%;
      grid-template-columns: 1fr;
    }
  
    .service-card {
      margin: 15px 0;
      padding: 15px;
    }
  
    h1 {
      font-size: 2em;
    }
    h1::after {
        width: 80px;
        height: 4px;
      }
  
    .service-card h2 {
      font-size: 1.5em;
    }
}
  

@media only screen and (min-width: 610px) and (max-width: 1200px){
  .services-container {
    height: 190vh;
  }

  h1{
      top: 0%;
  }

  .services-grid {
    margin-left: 6%;
    margin-top: -1%;
    grid-template-columns: 1fr;
  }

  .service-card {
    padding: 15px;
  }

  h1 {
    font-size: 2em;
  }
  h1::after {
      width: 80px;
      height: 4px;
    }

  .service-card h2 {
    font-size: 1.5em;
  }
}

