.home{
    position: relative;
}

.home .home-welcome{
    position: relative;
    height: 90vh;
}

.home .home-welcome .home-welcome-title{
    position: absolute;
    top: 20%;
    left: 8%;
    font-size: 600%;
    font-family: "Lora", serif;
}

.home .home-welcome .read-more-button-wrapper{
    position: absolute;
    width: 8%;
    height: 8%;
    top: 75%;
    left: 8%;
    border: 1px solid black;
}

.home .home-welcome .read-more-button-wrapper .read-more-button-link{
    position: absolute;
    text-decoration: none;
    width: 100%;
    height: 100%;
    font-family: 'Courier New', Courier, monospace;
    color: black;
}

.home .home-welcome .read-more-button-wrapper .read-more-button-link .read-more-button-text{
    position: relative;
    text-align: center;
    top: 30%;
}


.home .services-wrapper{
    position: relative;
    background-color: #FAF8ED;
    height: 80vh;
}


.home .services-wrapper .know-more-button-wrapper{
    position: absolute;
    width: 8%;
    height: 8%;
    top: 20%;
    left: 72%;
    border: 1px solid black;
}

.home .services-wrapper .know-more-button-wrapper .know-more-button-link{
    position: absolute;
    text-decoration: none;
    width: 100%;
    height: 100%;
    font-family: 'Courier New', Courier, monospace;
    color: black;
}

.home .services-wrapper .know-more-button-wrapper .know-more-button-link .know-more-button-text{
    position: relative;
    text-align: center;
    top: 30%;
}

.home .services-wrapper .services-title{
    position: absolute;
    left: 6%;
    top: 10%;
    font-family: "Lora", serif;
    font-size: 380%;
    font-weight: 500;
}

.home .services-wrapper .services-box{
    position: absolute;
    width: 90%;
    height: 58%;
    top: 36%;
    left: 6%;
}

.home .services-wrapper .services{
    position: absolute;
    width: 24%;
    height: 90%;
    top: 5%;
    left: 1%;
    border-left: 1px solid rgb(161, 161, 161);
    transition: 1s;
}

.home .services-wrapper .services:hover{
    background-color: white;
    box-shadow: 1px 1px 5px;
}

.home .services-wrapper .service-second{
    left: 25%;
}

.home .services-wrapper .service-third{
    left: 49%;
}

.home .services-wrapper .service-fourth{
    left: 73%;
}

.home .services-wrapper .services .service-content{
    position: absolute;
    width: 80%;
    height: 80%;
    left: 10%;
    top: 10%;
    border-top: 1px solid rgb(156, 156, 156);
}

.home .services-wrapper .services .service-content .service-no{
    position: absolute;
    top: 2%;
    color: rgb(240, 151, 34);
    font-size: 120%;
    font-family: 'Times New Roman', Times, serif;
}

.home .services-wrapper .services .service-content .service-heading{
    position: absolute;
    top: 50%;
    font-size: 250%;
    font-family: "Lora", serif;
}

.home .about-us-wrapper{
    position: relative;
    height: 100vh;
}

.home .about-us-wrapper .about-left-box{
    position: absolute;
    width: 40%;
    height: 90%;
    left: 8%;
    top: 5%;
}

.home .about-us-wrapper .about-left-box .about-title{
    position: absolute;
    top: 5%;
    left: 4%;
    font-family: "Lora", serif;
    font-size: 120%;
    color: rgb(240, 151, 34);
}

.home .about-us-wrapper .about-left-box .about-content{
    position: absolute;
    width: 100%;
    top: 10%;
    left: 4%;
    font-size: 280%;
    font-family: "Lora", serif;
}

.home .about-us-wrapper .about-left-box .read-more-button-wrapper{
    position: absolute;
    width: 20%;
    height: 8%;
    top: 40%;
    left: 4%;
    border: 1px solid black;
}

.home .about-us-wrapper .about-left-box .read-more-button-wrapper .read-more-button-link{
    position: absolute;
    text-decoration: none;
    width: 100%;
    height: 100%;
    font-family: 'Courier New', Courier, monospace;
    color: black;
}

.home .about-us-wrapper .about-left-box .read-more-button-wrapper .read-more-button-link .read-more-button-text{
    position: relative;
    text-align: center;
    top: 35%;
    color: rgb(226, 129, 2);
}

.home .about-us-wrapper .what-we-do-wrapper{
    position: absolute;
    border-left: 1px solid rgb(194, 194, 194);
    width: 48%;
    height: 90%;
    left: 50%;
    top: 5%;
}

.what-we-do-wrapper-title{
    position: absolute;
    top: 5%;
    left: 4%;
    font-family: "Lora", serif;
    font-size: 120%;
    color: rgb(240, 151, 34);
}

.home .about-us-wrapper .what-we-do-wrapper .what-we-do-wrapper-content{
    position: absolute;
    width: 100%;
    top: 10%;
    left: 4%;
    font-size: 400%;
    font-weight: bold;
    font-family: "Lora", serif;
}

.home .about-us-wrapper .what-we-do-wrapper .what-we-do-wrapper-sub-content{
    position: absolute;
    width: 100%;
    top: 105%;
    font-size: 55%;
    font-weight: normal;
}


@media screen and (max-width: 600px) {
    .home{
        position: relative;
    }
    
    .home .home-welcome{
        position: relative;
        height: 60vh;
    }
    
    .home .home-welcome .home-welcome-title{
        position: absolute;
        width: 90%;
        top: 8%;
        left: 5%;
        font-size: 340%;
    }
    
    .home .home-welcome .read-more-button-wrapper{
        width: 38%;
        height: 12%;
        top: 80;
        left: 5%;
        font-weight: bold;
        border: 2px solid black;
    }

    .home .services-wrapper{
        height: 200vh;
    }


    .home .services-wrapper .know-more-button-wrapper{
        width: 38%;
        height: 4%;
        top: 13%;
        left: 4%;
        font-weight: bold;
        border: 2px solid black;
    }

    .home .services-wrapper .services-title{
        position: absolute;
        width: 98%;
        left: 2%;
        top: 1%;
        font-size: 300%;
        font-weight: normal;
    }

    .home .services-wrapper .services-box{
        height: 78%;
        top: 20%;
        left: 5%;
    }

    .home .services-wrapper .services{
        width: 100%;
        height: 24%;
        top: 0%;
        left: 0%;
        border-bottom: 1px solid rgb(161, 161, 161);
        background-color: white;
        box-shadow: 1px 1px 10px;
    }


    .home .services-wrapper .service-second{
        top: 26%;
    }

    .home .services-wrapper .service-third{
        top: 51%;
    }

    .home .services-wrapper .service-fourth{
        top: 76%;
    }

    .home .about-us-wrapper{
        position: relative;
        height: 130vh;
        top: -5%;
    }
    
    .home .about-us-wrapper .about-left-box{
        width: 100%;
        height: 45%;
        left: 0%;
        top: 0%;
    }
    
    .home .about-us-wrapper .about-left-box .about-title{
        font-size: 250%;
        top: 10%;
        left: 30%;
        color:rgb(206, 117, 0);
    }
    
    .home .about-us-wrapper .about-left-box .about-content{
        width: 90%;
        top: 28%;
        left: 5%;
        font-size: 280%;
    }
    
    .home .about-us-wrapper .about-left-box .read-more-button-wrapper{
        width: 38%;
        height: 10%;
        top: 78%;
        left: 29%;
        font-weight: bold;
        border: 2px solid black;
    }

    .home .about-us-wrapper .what-we-do-wrapper{
        border-top: 1px solid rgb(194, 194, 194);
        width: 100%;
        height: 55%;
        top: 45%;
        left: 0%;
    }
    
    .what-we-do-wrapper-title{
        font-size: 180%;
        left: 29%;
        top: 8%;
    }
    
    .home .about-us-wrapper .what-we-do-wrapper .what-we-do-wrapper-content{
        position: absolute;
        width: 95%;
        top: 16%;
        left: 3%;
        font-size: 260%;
        font-weight: normal;
    }
    
    .home .about-us-wrapper .what-we-do-wrapper .what-we-do-wrapper-sub-content{
        position: absolute;
        width: 100%;
        top: 112%;
        left: 1%;
        font-size: 42%;
        font-weight: normal;
    }
}

@media only screen and (min-width: 610px) and (max-width: 1200px){

    .home{
        position: relative;
        height: 380vh;
    }

    .home .home-welcome{
        height: 58vh;
    }

    .home .home-welcome .home-welcome-title{
        position: relative;
        font-size: 350%;
        left: 0%;
        top: 18%;
        text-align: center;
    }


    .home .home-welcome .read-more-button-wrapper{
        top: 75%;
        left: 40%;
        width: 20%;
        height: 10%;
    }

    .home .services-wrapper{
        height: 180vh;
    }


    .home .services-wrapper .know-more-button-wrapper{
        top: 12%;
        left: 40%;
        width: 20%;
        height: 3%;
    }

    .home .services-wrapper .services-title{
        position: relative;
        text-align: center;
        left: 0%;
        top: 3%;
        font-size: 300%;
        font-weight: normal;
    }

    .home .services-wrapper .services-box{
        height: 78%;
        top: 20%;
        left: 5%;
    }

    .home .services-wrapper .services{
        width: 100%;
        height: 24%;
        top: 0%;
        left: 0%;
        border-bottom: 1px solid rgb(161, 161, 161);
        background-color: white;
        box-shadow: 1px 1px 10px;
    }


    .home .services-wrapper .service-second{
        top: 26%;
    }

    .home .services-wrapper .service-third{
        top: 51%;
    }

    .home .services-wrapper .service-fourth{
        top: 76%;
    }

    .home .about-us-wrapper{
        position: relative;
        height: 90vh;
        top: 1%;
    }
    
    .home .about-us-wrapper .about-left-box{
        position: relative;
        width: 100%;
        height: 55%;
        left: 0%;
        top: 0%;
    }
    
    .home .about-us-wrapper .about-left-box .about-title{
        position: relative;
        left: 0%;
        text-align: center;
        font-size: 250%;
        color:rgb(206, 117, 0);
    }
    
    .home .about-us-wrapper .about-left-box .about-content{
        position: relative;
        text-align: center;
        top: 15%;
        left: 0%;
        font-size: 280%;
    }
    
    .home .about-us-wrapper .about-left-box .read-more-button-wrapper{
        width: 22%;
        height: 15%;
        top: 70%;
        left: 39%;
        font-weight: bold;
        border: 2px solid black;
    }

    .home .about-us-wrapper .what-we-do-wrapper{
        border-top: 1px solid rgb(194, 194, 194);
        position: relative;
        width: 100%;
        height: 55%;
        top: 0%;
        left: 0%;
    }
    
    .what-we-do-wrapper-title{
        position: relative;
        font-size: 250%;
        left: 0%;
        top: 8%;
        text-align: center;
    }
    
    .home .about-us-wrapper .what-we-do-wrapper .what-we-do-wrapper-content{
        position: relative;
        text-align: center;
        top: 12%;
        left: 0%;
        font-size: 300%;
        font-weight: normal;
    }
    
    .home .about-us-wrapper .what-we-do-wrapper .what-we-do-wrapper-sub-content{
        position: absolute;
        width: 100%;
        top: 112%;
        left: 0%;
        font-size: 42%;
        font-weight: normal;
    }
}


@media only screen and (min-width: 1205px) and (max-width:1600px){
    .home{
        position: relative;
        height: 300vh;
    }
    
    .home .home-welcome{
        position: relative;
        height: 90vh;
    }
    
    .home .home-welcome .home-welcome-title{
        position: absolute;
        top: 24%;
        left: 5%;
        font-size: 560%;
        font-family: "Lora", serif;
    }
    
    .home .home-welcome .read-more-button-wrapper{
        position: absolute;
        width: 9%;
        height: 7%;
        top: 75%;
        left: 5%;
        border: 1px solid black;
    }
    
    .home .home-welcome .read-more-button-wrapper .read-more-button-link{
        position: absolute;
        text-decoration: none;
        width: 100%;
        height: 100%;
        font-family: 'Courier New', Courier, monospace;
        color: black;
    }
    
    .home .home-welcome .read-more-button-wrapper .read-more-button-link .read-more-button-text{
        position: relative;
        text-align: center;
        top: 35%;
    }
    
    
    .home .services-wrapper{
        position: relative;
        background-color: #FAF8ED;
        height: 80vh;
        top: -1%;
    }
    
    
    .home .services-wrapper .know-more-button-wrapper{
        position: absolute;
        width: 9%;
        height: 7%;
        top: 20%;
        left: 72%;
        border: 1px solid black;
    }
    
    .home .services-wrapper .know-more-button-wrapper .know-more-button-link{
        position: absolute;
        text-decoration: none;
        width: 100%;
        height: 100%;
        font-family: 'Courier New', Courier, monospace;
        color: black;
    }
    
    .home .services-wrapper .know-more-button-wrapper .know-more-button-link .know-more-button-text{
        position: relative;
        text-align: center;
        top: 35%;
    }
    
    .home .services-wrapper .services-title{
        position: absolute;
        left: 5%;
        top: 10%;
        font-family: "Lora", serif;
        font-size: 380%;
        font-weight: 500;
    }
    
    .home .services-wrapper .services-box{
        position: absolute;
        width: 98%;
        height: 58%;
        top: 36%;
        left: 1%;
    }
    
    .home .services-wrapper .services{
        position: absolute;
        width: 24%;
        height: 90%;
        top: 5%;
        left: 1%;
        border-left: 1px solid rgb(161, 161, 161);
        transition: 1s;
    }
    
    .home .services-wrapper .services:hover{
        background-color: white;
        box-shadow: 1px 1px 5px;
    }
    
    .home .services-wrapper .service-second{
        left: 25%;
    }
    
    .home .services-wrapper .service-third{
        left: 49%;
    }
    
    .home .services-wrapper .service-fourth{
        left: 73%;
    }
    
    .home .services-wrapper .services .service-content{
        position: absolute;
        width: 80%;
        height: 80%;
        left: 10%;
        top: 10%;
        border-top: 1px solid rgb(156, 156, 156);
    }
    
    .home .services-wrapper .services .service-content .service-no{
        position: absolute;
        top: 2%;
        color: rgb(240, 151, 34);
        font-size: 120%;
        font-family: 'Times New Roman', Times, serif;
    }
    
    .home .services-wrapper .services .service-content .service-heading{
        position: absolute;
        top: 50%;
        font-size: 250%;
        font-family: "Lora", serif;
    }
    
    .home .about-us-wrapper{
        position: relative;
        height: 100vh;
    }
    
    .home .about-us-wrapper .about-left-box{
        position: absolute;
        width: 40%;
        height: 90%;
        left: 8%;
        top: 5%;
    }
    
    .home .about-us-wrapper .about-left-box .about-title{
        position: absolute;
        top: 5%;
        left: 4%;
        font-family: "Lora", serif;
        font-size: 120%;
        color: rgb(240, 151, 34);
    }
    
    .home .about-us-wrapper .about-left-box .about-content{
        position: absolute;
        width: 100%;
        top: 10%;
        left: 4%;
        font-size: 280%;
        font-family: "Lora", serif;
    }
    
    .home .about-us-wrapper .about-left-box .read-more-button-wrapper{
        position: absolute;
        width: 22%;
        height: 7%;
        top: 40%;
        left: 4%;
        border: 1px solid black;
    }
    
    .home .about-us-wrapper .about-left-box .read-more-button-wrapper .read-more-button-link{
        position: absolute;
        text-decoration: none;
        width: 100%;
        height: 100%;
        font-family: 'Courier New', Courier, monospace;
        color: black;
    }
    
    .home .about-us-wrapper .about-left-box .read-more-button-wrapper .read-more-button-link .read-more-button-text{
        position: relative;
        text-align: center;
        top: 35%;
        color: rgb(226, 129, 2);
    }
    
    .home .about-us-wrapper .what-we-do-wrapper{
        position: absolute;
        border-left: 1px solid rgb(194, 194, 194);
        width: 48%;
        height: 90%;
        left: 50%;
        top: 5%;
    }
    
    .what-we-do-wrapper-title{
        position: absolute;
        top: 5%;
        left: 4%;
        font-family: "Lora", serif;
        font-size: 120%;
        color: rgb(240, 151, 34);
    }
    
    .home .about-us-wrapper .what-we-do-wrapper .what-we-do-wrapper-content{
        position: absolute;
        width: 100%;
        top: 10%;
        left: 4%;
        font-size: 400%;
        font-weight: bold;
        font-family: "Lora", serif;
    }
    
    .home .about-us-wrapper .what-we-do-wrapper .what-we-do-wrapper-sub-content{
        position: absolute;
        width: 100%;
        top: 105%;
        font-size: 55%;
        font-weight: normal;
    }
    
}